<template>
  <div>
    <NavigationDrawer
      :navbar="navbar"
      @onNavBar="changeNavBar"
      v-if="windowsWidth > 900"
    />

    <AppBar
      :navbar="navbar"
      @onNavBar="changeNavBar"
      @onDark="setDark"
      :windowsWidth="windowsWidth"
    />

    <v-main class="background">
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>

    <BottomNavigation v-if="windowsWidth < 900" />
    <!-- <FooterApp /> -->
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";
import axios from "../plugins/axios";

export default {
  name: "Portal",

  components: {
    AppBar: () => import("../components/AppBar"),
    NavigationDrawer: () => import("../components/NavigationDrawer"),
    //FooterApp: () => import("./shared/components/Footer"),
    BottomNavigation: () => import("../components/BottomNavigation"),
  },

  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpgradeUI = true;
      });
    }
  },

  mounted() {
    this.windowsWidth = window.innerWidth;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.initService();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  data: () => ({
    buff: "Sub1:<br>",
    navbar: false,
    windowsWidth: null,
    messaging: null,
    isLogin: false,
  }),

  methods: {
    initService() {
      this.initFirebase();
      let isService =
        localStorage.getItem("service-notification") === "true" ? true : false;
      if (!isService) {
        if ("serviceWorker" in navigator) {
          window.addEventListener("load", function() {
            this.navigator.serviceWorker.getRegistrations();
            this.navigator.serviceWorker
              .register("/firebase-messaging-sw.js")
              .then((e) => {
                e.onupdatefound = function() {
                  var t = e.installing;
                  t.onstatechange = function() {
                    switch (t.state) {
                      case "installed":
                        localStorage.setItem("service-notification", "true");
                        if (navigator.serviceWorker.controller) {
                          console.log("new update available");
                        }
                        break;
                      default:
                    }
                  };
                };
              })
              .catch((e) => {
                console.log("ServiceWorker registration failed: ", e);
              });
          });
        }
      }
    },
    async checkPermission() {
      if (window.Notification) {
        if (Notification.permission === "granted") {
          this.getToken();
        } else {
          let vm = this;
          Notification.requestPermission(function(result) {
            if (result === "denied") {
              vm.$toast.info(
                "Las notificaciones se han bloqueado, se solicita porfavor habilitar las notificaciones para tener la experiencia completa."
              );
              return;
            } else if (result === "default") {
              vm.$toast.info(
                "Porfavor, se solicita habilitar las notificaciones para tener la experiencia completa."
              );
              return;
            }
            vm.checkPermission();
          });
        }
      } else {
        let vm = this;
        vm.$toast.info(
          "Su dispositivo no es compatible con las notificaciones."
        );
      }
    },
    async getToken() {
      await this.messaging
        .getToken({
          vapidKey:
            "BNTyR0VvSu4xWTV4RsyzwSW6dzWXpvO3Kfn_-uKsOiMcF271EdDNwbqQeQZRFPsJpumCW5Ch0ouTyvbwbklAD6s",
        })
        .then((currentToken) => {
          if (currentToken) {
            localStorage.setItem("tokenDispositive", currentToken);
            let email = sessionStorage.getItem("email");
            let token = sessionStorage.getItem("token");
            if (email && token) {
              let user = {
                email,
                sessionToken: token,
                dispositive: navigator.userAgent,
                pushToken: currentToken,
              };
              axios
                .post(`auth/enablepush`, user)
                .then((response) => {
                  if (response) {
                    localStorage.setItem("service-firebase", "true");
                    this.notificationListener();
                  }
                })
                .catch((error) => {
                  if (error) {
                    this.$toast.error(
                      "No ha sido posible habilitar las notificaciones."
                    );
                  }
                });
            }
          }
        });
    },
    notificationListener() {
      this.messaging.onMessage((payload) => {
        console.log("Message received. ", payload);
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
          body: payload.notification.body,
          vibrate: [200, 100, 200, 100, 200, 100, 200],
          icon:
            "https://smartpill.cristian-molina.dev/img/smartpill_icon.612a218a.png",
          tag: "renotify",
          renotify: true,
        };
        new Notification(notificationTitle, notificationOptions);
      });
      this.messaging.on;
    },
    initFirebase() {
      const firebaseConfig = {
        apiKey: "AIzaSyCYFHeRZWc8muQN1iJjzyobHnSY01xB0JQ",
        authDomain: "smartpill-36c64.firebaseapp.com",
        databaseURL: "https://smartpill-36c64.firebaseio.com",
        projectId: "smartpill-36c64",
        storageBucket: "smartpill-36c64.appspot.com",
        messagingSenderId: "416397435354",
        appId: "1:416397435354:web:b69d7f60781d6f4589a0e3",
        measurementId: "G-CKBKW52QTW",
      };
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.analytics();
      this.messaging = firebase.messaging();
      this.checkPermission();
    },
    async accept() {
      this.showUpgradeUI = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
    changeNavBar(value) {
      this.navbar = value;
    },
    onResize() {
      this.windowsWidth = window.innerWidth;
    },
    setDark(value) {
      localStorage.setItem("dark", value);
    },
  },
};
</script>

<style scoped></style>
